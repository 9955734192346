import PropTypes from 'prop-types'

export const ClientRequestSpecType = PropTypes.shape({
  id: PropTypes.string,
  params: PropTypes.object,
  missingParams: PropTypes.arrayOf(PropTypes.string)
})

export const AnswerArticleType = PropTypes.shape({
  title: PropTypes.string,
  source: PropTypes.string
})

export const AnswerPropTypes = PropTypes.shape({
  id: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  metadata: PropTypes.object,
  articles: PropTypes.arrayOf(AnswerArticleType),
  clientRequestSpec: ClientRequestSpecType
})

export const QuestionPropTypes = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  answers: PropTypes.arrayOf(AnswerPropTypes)
})
