import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'
import ReactMarkdown from 'react-markdown'
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font
} from '@react-pdf/renderer'

import { AnswerPropTypes } from '../types'
import { COLORS } from 'COLORS'
import { JSPRO_BUCKET } from 'constants/s3'

Font.register({
  family: 'Inter',
  fonts: [
    {
      src:
        'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf'
    },
    {
      src:
        'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf',
      fontWeight: 'bold'
    }
  ]
})

const styles = StyleSheet.create({
  /** PAGE STYLES */
  page: {
    padding: '28px 20px',
    fontFamily: 'Inter',
    fontSize: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    lineHeight: 1.6,
    color: COLORS.grey900,
    textAlign: 'left'
  },
  /** TEXT STYLES */
  h1: {
    color: COLORS.primary,
    fontSize: '14px',
    fontWeight: 600,
    margin: '12px 0 2px 0'
  },
  h2: {
    fontSize: '12px',
    fontWeight: 600
  },
  bodyText: {
    fontSize: '10px'
  },
  smallText: {
    fontSize: '8px'
  },
  extraSmallText: {
    fontSize: '5px'
  },
  boldText: {
    fontWeight: 600
  },
  title: {
    fontSize: '18px',
    fontWeight: 600
  },
  /** VIEW STYLES */
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  subheader: {
    margin: '8px 0 2px 0'
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    color: COLORS.grey600,
    marginTop: '12px'
  },
  footerSection: {
    flex: 1
  },
  exportDetails: {
    display: 'flex',
    flexDirection: 'column',
    color: COLORS.grey600
  },
  bodyContent: {
    gap: '8px',
    width: '542px' // https://github.com/diegomura/react-pdf/issues/2182
  },
  logo: {
    width: '68px',
    height: 'auto'
  },
  poweredBy: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  /** LIST STYLES */
  list: {
    paddingLeft: '6px'
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row'
  },
  listItemBullet: {
    paddingRight: '6px'
  }
})

// https://github.com/diegomura/react-pdf/issues/134
const ListItem = ({ children }) => {
  const filteredChildren = children.filter(item => item !== '\n') // Remove new lines from list items

  return (
    <View wrap={false} style={styles.listItem}>
      <Text style={styles.listItemBullet}>{'\u2022'}</Text>
      <View>
        <Text style={styles.bodyText}>{filteredChildren}</Text>
      </View>
    </View>
  )
}
ListItem.propTypes = {
  children: PropTypes.node.isRequired
}

/* eslint-disable react/prop-types, react/jsx-props-no-spreading */
const markdownToPDF = markdown => (
  <ReactMarkdown
    disallowedElements={['hr', 'code', 'td', 'th', 'tr']}
    components={{
      h1(props) {
        const { node, ...rest } = props
        return <Text style={styles.h1} {...rest} />
      },
      h2(props) {
        const { node, ...rest } = props
        return <Text style={styles.h2} {...rest} />
      },
      h3(props) {
        const { node, ...rest } = props
        return <Text style={styles.h2} {...rest} />
      },
      h4(props) {
        const { node, ...rest } = props
        return <Text style={styles.bodyText} {...rest} />
      },
      h5(props) {
        const { node, ...rest } = props
        return <Text style={styles.bodyText} {...rest} />
      },
      h6(props) {
        const { node, ...rest } = props
        return <Text style={styles.bodyText} {...rest} />
      },
      strong(props) {
        const { node, ...rest } = props
        return <Text style={styles.boldText} {...rest} />
      },
      p(props) {
        const { node, ...rest } = props
        return <Text style={styles.bodyText} {...rest} />
      },
      ul(props) {
        const { node, ...rest } = props
        return <View style={styles.list} {...rest} />
      },
      ol(props) {
        const { node, ...rest } = props
        return <View style={styles.list} {...rest} />
      },
      li(props) {
        const { index, children } = props
        return <ListItem key={index}>{children}</ListItem>
      }
    }}>
    {markdown}
  </ReactMarkdown>
)

/**
 * This PDF document uses the react-pdf library.
 * It only renders using a specific set of components, mainly View and Text. Normal jsx gets ignored.
 * Styled-components does not work with this, and the bindings library for react-pdf has been deprecated.
 * Must use the react-pdf StyleSheet to style components.
 *
 * Read more: https://react-pdf.org/components
 */
const ReportPDF = ({ answer }) => {
  const { account, createdBy, exportedAt, headerInfo } =
    answer.metadata?.pdf || {}

  const renderHeader = () => {
    return (
      <>
        <View style={styles.header}>
          <Text style={styles.title}>AI Analysis</Text>
          <View style={styles.exportDetails}>
            <Text
              style={{
                ...styles.smallText,
                ...styles.boldText,
                color: COLORS.grey900
              }}>
              {account}
            </Text>
            <Text style={styles.smallText}>Created by: {createdBy}</Text>
            <Text style={styles.smallText}>
              Exported: {moment(exportedAt).format('MMM D, YYYY')}
            </Text>
          </View>
        </View>
        <View style={styles.subheader}>
          {headerInfo?.map(info => (
            <Text key={info.label} style={styles.bodyText}>
              <Text style={styles.boldText}>{info.label}: </Text>
              {info.value}
            </Text>
          ))}
        </View>
      </>
    )
  }

  const renderFooter = () => {
    return (
      <View fixed style={styles.footer}>
        <View style={styles.footerSection}>
          <Text style={styles.extraSmallText}>
            Generated by AI Assist. Accuracy of results may vary.
          </Text>
        </View>
        <View style={{ ...styles.footerSection, textAlign: 'center' }}>
          <Text
            style={styles.smallText}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber}/${totalPages}`
            }
          />
        </View>
        <View style={{ ...styles.poweredBy, ...styles.footerSection }}>
          <Text style={{ ...styles.extraSmallText, ...styles.boldText }}>
            POWERED BY
          </Text>
          <Image
            style={styles.logo}
            src={`${JSPRO_BUCKET}/images/logos/js-menu-logo.png`}
          />
        </View>
      </View>
    )
  }

  return (
    <Document title="AI Analysis" author="Jungle Scout AI Assist">
      <Page style={styles.page} size="A4">
        <View>
          {renderHeader()}
          <View style={styles.bodyContent}>
            {markdownToPDF(answer.text.trim())}
          </View>
        </View>
        {renderFooter()}
      </Page>
    </Document>
  )
}

ReportPDF.propTypes = {
  answer: AnswerPropTypes.isRequired
}

export { ReportPDF }
