import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { CHAT_SPACING } from 'constants/chatbot'
import { QuestionPropTypes } from './types'

import { QuestionSender } from './QuestionSender'
import { QuestionBubble } from './QuestionBubble'

import { Answer } from './Answer'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${CHAT_SPACING}px;
`

const Question = ({
  question,
  createAnswer,
  streamingString,
  isStreaming,
  isLastOne
}) => {
  const hasAnswers = question.answers?.length > 0 || isStreaming

  return (
    <Container>
      {question.text && (
        <QuestionSender isUser>
          <QuestionBubble text={question.text} isUser />
        </QuestionSender>
      )}

      {hasAnswers && (
        <QuestionSender>
          {question.answers?.map((answer, i) => {
            const isLastAnswer = i === question.answers.length - 1
            return (
              <Answer
                key={`question-${question.id}-answer-${i}`}
                question={question}
                answer={answer}
                isStreaming={isStreaming}
                isLastOne={isLastOne && isLastAnswer}
                createAnswer={createAnswer}
              />
            )
          })}

          {isStreaming && <QuestionBubble text={streamingString} />}
        </QuestionSender>
      )}
    </Container>
  )
}

Question.propTypes = {
  question: QuestionPropTypes.isRequired,
  createAnswer: PropTypes.func.isRequired,
  streamingString: PropTypes.string,
  isStreaming: PropTypes.bool,
  isLastOne: PropTypes.bool
}

Question.defaultProps = {
  streamingString: undefined,
  isStreaming: false,
  isLastOne: false
}

export { Question }
