import React, { memo } from 'react'

import { PDFDownloadLink } from '@react-pdf/renderer'

import { sendCtaClickEvent } from 'helpers/segment'

import { ReportPDF } from './ReportPDF'
import { AnswerPropTypes } from '../types'
import { ChatButton } from '../../ChatButton'

const ExportPDFButton = memo(
  ({ answer }) => {
    const fileName = answer.metadata?.pdf?.fileName || 'AIReport'
    return (
      <PDFDownloadLink
        document={<ReportPDF answer={answer} />}
        fileName={fileName}
        onClick={() =>
          sendCtaClickEvent({ text: 'AI Assist Chat - Export PDF' })
        }>
        <ChatButton icon="DOWNLOAD">Export PDF</ChatButton>
      </PDFDownloadLink>
    )
  },
  (prevProps, nextProps) => prevProps.answer.id === nextProps.answer.id
)

ExportPDFButton.propTypes = {
  answer: AnswerPropTypes.isRequired
}

export { ExportPDFButton }
