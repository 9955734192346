import React from 'react'
import styled, { keyframes } from 'styled-components'

import { COLORS } from 'COLORS'

const DARK = COLORS.grey500
const LIGHT = COLORS.grey100
const DOT_SIZE = '8px'
const DOT_GAP = '12px'

const ellipsisAnimation = keyframes`
    0% {
      background-color: ${DARK};
      box-shadow: -${DOT_GAP} 0 0 ${DARK}, ${DOT_GAP} 0 0 ${DARK};
    }
    20% {
      background-color: ${DARK};
      box-shadow: -${DOT_GAP} 0 0 ${LIGHT}, ${DOT_GAP} 0 0 ${DARK};
    }
    40% {
      background-color: ${LIGHT};
      box-shadow: -${DOT_GAP} 0 0 ${LIGHT}, ${DOT_GAP} 0 0 ${DARK};
    }
    60% {
      background-color: ${LIGHT};
      box-shadow: -${DOT_GAP} 0 0 ${DARK}, ${DOT_GAP} 0 0 ${LIGHT};
    }
    80% {
      background-color: ${DARK};
      box-shadow: -${DOT_GAP} 0 0 ${DARK}, ${DOT_GAP} 0 0 ${LIGHT};
    }
    100% {
      background-color: ${DARK};
      box-shadow: -${DOT_GAP} 0 0 ${DARK}, ${DOT_GAP} 0 0 ${DARK};
    }
  `
const Dots = styled.div`
  animation: ${ellipsisAnimation} 1s linear infinite;
  background-color: ${LIGHT};
  border-radius: 100%;
  box-shadow: -${DOT_GAP} 0 0 ${LIGHT}, ${DOT_GAP} 0 0 ${LIGHT};
  display: block;
  height: ${DOT_SIZE};
  width: ${DOT_SIZE};
  margin-left: ${DOT_GAP};
`
const Ellipsis = styled.div`
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 8px;
`

const QuestionTyping = () => (
  <Ellipsis aria-label="Loading">
    <Dots />
  </Ellipsis>
)

export { QuestionTyping }
