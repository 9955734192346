import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'

import { Avatar, Icon } from '@junglescout/edna'
import { CHAT_SPACING, AVATAR_SIZE } from 'constants/chatbot'

const Container = styled.section`
  width: 100%;
  display: flex;
  gap: ${CHAT_SPACING}px;
  padding-top: ${CHAT_SPACING}px;
  flex-direction: ${props => (props.$isUser ? 'row-reverse' : 'row')};
`
const Content = styled.div`
  max-width: ${`calc(100% - ${isMobile ? 1 : 2 * AVATAR_SIZE}px)`};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
`
const IconWrapper = styled.div`
  height: ${AVATAR_SIZE}px;
  width: ${AVATAR_SIZE}px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #f57706 0%, #ffb66a 100%);
`

const AvatarSpacer = styled.div`
  width: ${AVATAR_SIZE}px;
  min-width: ${AVATAR_SIZE}px;
`

const UserAvatar = () => {
  const fullName = useSelector(state => state.globalData?.user?.full_name)
  const image = useSelector(state => state.globalData?.user?.account_photo)
  return <Avatar fullName={fullName} image={image} />
}

const QuestionSender = ({ children, isUser }) => (
  <Container $isUser={isUser}>
    <AvatarSpacer>
      {isUser ? (
        <UserAvatar />
      ) : (
        <IconWrapper>
          <Icon name="AI_ASSIST" color="white" />
        </IconWrapper>
      )}
    </AvatarSpacer>
    <Content>{children}</Content>
    {!isMobile && <AvatarSpacer />}
  </Container>
)

QuestionSender.propTypes = {
  children: PropTypes.node.isRequired,
  isUser: PropTypes.bool
}

QuestionSender.defaultProps = {
  isUser: false
}

export { QuestionSender }
