import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

import { QuestionTyping } from './QuestionTyping'

const Bubble = styled.div`
  padding: 12px;
  border-radius: 16px;
  width: auto;
  min-height: 44px;
  word-wrap: break-word;
  margin-bottom: 8px;
  ${props =>
    props.$isUser
      ? `
        background: ${props.theme.colors.primary200};
        border-top-right-radius: 0;
    `
      : `
        background: ${props.theme.colors.grey050};
        border-top-left-radius: 0;
    `}
`

const StyledMarkdown = styled(ReactMarkdown)`
  color: ${props => props.theme.colors.grey900};

  a {
    text-decoration: underline;
  }

  p {
    margin: 0;
    margin-bottom: 8px;
  }

  ol,
  ul {
    padding-inline-start: 20px;
    margin: 0;
    margin-bottom: 8px;
  }

  strong {
    font-weight: 600;
  }

  h1,
  h2,
  h3 {
    font-weight: 600;
    margin: 0;
    margin-bottom: 8px;
  }

  h1 {
    font-size: 15px;
    color: ${props => props.theme.colors.primary};
  }
  h2 {
    font-size: 14px;
  }
  h3 {
    font-size: 13px;
  }

  *:last-child {
    margin-bottom: 0 !important;
  }
`

const QuestionBubble = ({ text, isUser }) => (
  <Bubble $isUser={isUser}>
    {text?.length ? (
      <StyledMarkdown>{text}</StyledMarkdown>
    ) : (
      <QuestionTyping />
    )}
  </Bubble>
)

QuestionBubble.propTypes = {
  text: PropTypes.string,
  isUser: PropTypes.bool
}

QuestionBubble.defaultProps = {
  text: undefined,
  isUser: false
}

export { QuestionBubble }
