import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { TEXT_STYLES } from 'constants/text_styles'
import { CHAT_SPACING } from 'constants/chatbot'

import { QuestionSender } from './QuestionSender'
import { QuestionBubble } from './QuestionBubble'
import { AnswerSuggestion } from './AnswerSuggestion'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${CHAT_SPACING * 2}px;
`

const SuggestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 8px;
  padding: 0 ${CHAT_SPACING}px;
`

const SuggestionLabel = styled.div`
  ${TEXT_STYLES.H5Inactive};
`

const QuestionEmpty = ({ sendQuestion }) => {
  const firstName = useSelector(state => state.account?.userInfo?.firstName)

  const suggestions = [
    { question: 'What were my sales last month?', endpoint: 'get_units_sold' },
    {
      question: 'How much have I lost to refunds this year?',
      endpoint: 'get_revenue'
    },
    { question: 'How do I find the best product to sell?' }
  ]

  return (
    <Container>
      <QuestionSender>
        <QuestionBubble
          text={`Hey ${firstName} 👋, welcome to AI Assist Chat! \n\n As an AI Assist Chat, I'm here to help you navigate the Amazon Jungle and answer questions about selling on Amazon. What can I help you with today?`}
        />
        <SuggestionsContainer>
          <SuggestionLabel>AI Assist Suggestions:</SuggestionLabel>
          {suggestions.map((suggestion, i) => (
            <AnswerSuggestion
              key={`suggestion-${i.toString()}`}
              suggestion={suggestion}
              onClick={() => sendQuestion({ question: suggestion.question })}
            />
          ))}
        </SuggestionsContainer>
      </QuestionSender>
    </Container>
  )
}

QuestionEmpty.propTypes = {
  sendQuestion: PropTypes.func.isRequired
}

export { QuestionEmpty }
